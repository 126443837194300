<template>
  <div class="sign_in_wrap">
    <el-row :gutter="15" class="point-box">
      <el-col :span="8">
        <div class="grid">
          <div class="icon">
            <img src="@/assets/img/icon-item1.png" />
          </div>
          <div class="content">
            <div class="title">游戏换好礼</div>
            <div class="text">你负责玩，我负责送，边玩边送</div>
            <div class="text"><span>{{ integral }}</span>积分</div>
            <el-button class="btn" type="primary" size="mini" plain round @click="$router.push('/points')">去兑换</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="grid">
          <div class="content">
            <div class="title">已经签到<i>{{ signInTotal }}</i>天
              <span>你已经超过
                <i v-if="signInTotal >= 0 && signInTotal <= 5">15%</i>
                <i v-else-if="signInTotal > 5 && signInTotal <= 10">30%</i>
                <i v-else-if="signInTotal > 10 && signInTotal <= 20">50%</i>
                <i v-else-if="signInTotal > 20 && signInTotal <= 30">75%</i>
                <i v-else>97%</i>
                的签到用户</span>
            </div>
            <div class="date-check">
              <ul>
                <li v-for="(date, index) in previousThreeDays" :key="index">
                  <div class="box" v-if="signInList.indexOf(date) > -1">
                    <img src="@/assets/img/23.png" alt="">
                  </div>
                  <div class="box" v-else>
                    <img src="@/assets/img/22.png" alt="">
                  </div>
                  {{ date }}
                </li>
              </ul>
              <ul>
                <li>
                  <div class="box">
                    <span>今日</span>
                    <div v-if="signInList.indexOf(new Date().toISOString().split('T')[0]) > -1">
                      <img src="@/assets/img/23.png" alt="">
                    </div>
                    <div class="round" v-else>
                      +{{ IntegralReward + 1 }}
                    </div>
                  </div>
                  <!-- {{ new Date().toISOString().split('T')[0] }} -->
                  <el-button type="primary" size="mini" @click="signIn"
                    :disabled="signInList.indexOf(new Date().toISOString().split('T')[0]) > -1 ? true : false">
                    {{ signInList.indexOf(new Date().toISOString().split('T')[0]) > -1 ? '已签到' : '签到' }}
                  </el-button>
                </li>
              </ul>
              <ul>
                <li v-for="(date, index) in nextThreeDays" :key="index">
                  <div class="box">
                    <div class="round">
                      +{{ IntegralReward < 5 ? (IntegralReward + 1 + index + 1) : 7 }} </div>
                    </div>
                    {{ date }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

    <div class="task-box">
      <div class="title-box">
        <img src="@/assets/img/points.png" alt="" /> 做任务赚积分
      </div>
      <div class="task">
        <el-row :gutter="100">
          <el-col :span="12" v-for="(item, index) in taskList" :key="index">
            <div class="grid">
              <div class="left">
                <img class="icon" src="@/assets/img/jf.png" alt="" />
                <span>+{{ item.IntegralReward }}</span>
                {{ item.Title }}
              </div>
              <el-button type="primary" :disabled="item.ConsumerIntegral < item.Integrallimit ? false : true" size="small"
                plain round @click="toComplete(item)">{{ item.ConsumerIntegral < item.Integrallimit ? '去完成' : '已完成'
                }}</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>

    <div class="points-mall">
      <div class="top-box">
        <div class="title-box">
          <img src="@/assets/img/points.png" alt="" /> 积分商城
        </div>
        <div class="a-box">
          <router-link to="/points">更多<i class="el-icon-d-arrow-right"></i></router-link>
        </div>
      </div>

      <div class="main-box">
        <div class="main-item" v-for="(item, index) in gameList" :key="index">
          <a href="javascript:;" class="link-box" @click="toPointMall(item)">
            <img
              :src="`${item.ProductPictureList.filter(i => i.CategoryID == 2)[0] && item.ProductPictureList.filter(i => i.CategoryID == 2)[0].PicUrl}`"
              class="game-img" alt />

            <div class="item-wrapper">
              <div class="item-box">
                <h4>{{ item.ProductName }}</h4>
                <div class="btn-experience">
                  <div class="left">{{ item.Price }}积分</div>
                  <div class="right">￥{{ item.OriginalPrice }}</div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { md5 } from "@/util/crypto";
import { GetIntegralTasks, GetCheckInLog, CompleteTask, GetGameTime } from '@/api'

export default {
  computed: {
    ...mapGetters(["isLogin", "userInfo", "netBarId"]),
    previousThreeDays() {
      let dates = [];
      for (let i = 0; i < 2; i++) {
        let date = new Date();
        date.setDate(date.getDate() - (i + 1));
        dates.unshift(date.toISOString().split('T')[0]);
      }
      return dates;
    },
    nextThreeDays() {
      let dates = [];
      for (let i = 0; i < 4; i++) {
        let date = new Date();
        date.setDate(date.getDate() + (i + 1));
        dates.push(date.toISOString().split('T')[0]);
      }
      return dates;
    }
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 4,
      total: 0,
      gameList: [],
      integral: 0,
      taskList: [],
      signInList: [],
      IntegralReward: 0,
      signInTotal: 0
    }
  },
  created() {
    if (this.isLogin && this.userInfo) {
      this._GetIntegralTasks();
    }
    _czc.push(["_trackEvent", "积分商城", "做任务赚积分", "访问量"]);
  },
  mounted() {
    if (this.isLogin && this.userInfo) {
      this.getUserScore();
      this._GetCheckInLog();
    }
    this.getProductData();
    this._GetGameTime();
  },
  methods: {
    async _CompleteTask(typeId) {
      if (this.userInfo && this.userInfo.ConsumerId && this.netBarId) {
        let params = {
          UserId: this.userInfo.ConsumerId,
          NetbarId: parseInt(this.netBarId),
          Type: parseInt(typeId)
        }
        let res = await CompleteTask(params)
        if (res.StatusCode == 200) {
          // this.$message.success("签到成功！")
          this._GetCheckInLog();
        }
      }
    },
    // 签到
    signIn() {
      this._CompleteTask(10);
    },
    // 获取7天签到数据
    async _GetCheckInLog() {
      let params = {
        consumerId: this.userInfo.ConsumerId,
      }
      let res = await GetCheckInLog(params);
      if (res.StatusCode == 200) {
        this.signInTotal = res.Message;
        if (res.Object.length) {
          this.signInList = [];
          let day = this.getYesterDay();

          res.Object.map(item => {
            if (item.CreateTime) {
              this.signInList.push(item.CreateTime.substr(0, 10))
              if (item.CreateTime.indexOf(day) > -1) {
                this.IntegralReward = parseInt(item.IntegralReward)
              }
            }
          })
        }
      }
    },
    // 获取昨天日期
    getYesterDay() {
      let todayTimestamp = Date.now(); // 获取当前时间的时间戳
      let yesterdayTimestamp = todayTimestamp - 24 * 60 * 60 * 1000; // 获取昨天的时间戳（减去一天的毫秒数）
      let yesterday = new Date(yesterdayTimestamp); // 将时间戳转换为日期对象
      let year = yesterday.getFullYear(); // 获取昨天的年份
      let month = yesterday.getMonth() + 1; // 获取昨天的月份
      let day = yesterday.getDate(); // 获取昨天的日期
      return year + '-' + month + '-' + day
    },
    // 去完成积分任务
    toComplete(item) {
      if (this.isLogin && this.userInfo) {
        this.$router.push(item.ImgUrl2)
      }
    },
    // 跳转积分商城
    toPointMall(item) {
      this.$router.push('/points?ProductCode=' + item.ProductCode)
    },
    // 获取积分任务
    async _GetIntegralTasks() {
      let params = {
        consumerId: this.userInfo && this.userInfo.ConsumerId,
        isShow: 1
      }
      let res = await GetIntegralTasks(params)
      if (res.StatusCode == 200) {
        this.taskList = res.Object
      }
    },
    // 获取个人积分
    getUserScore() {
      if (!this.userInfo.Phone) {
        eventBus.$emit("showLoginPhone");
        return false;
      }

      let str = this.userInfo.Phone + "-THB-WEB-FOR-CLIENT-";

      let params = {
        Phone: this.userInfo.Phone,
        Token: md5(str).toUpperCase()
      }
      $.ajax({
        url: "https://score.haoyouzhushou.com/App/User/GetToken",
        type: 'POST',
        data: JSON.stringify(params),
        contentType: 'application/json',
        dataType: 'json',
        success: (res) => {
          if (res.StatusCode == 200) {
            let Token = res.Data.Token;

            $.ajax({
              url: "https://score.haoyouzhushou.com/App/User/GetUserScore",
              type: 'POST',
              contentType: 'application/json',
              headers: {
                'Authorization': 'Bearer ' + Token
              },
              dataType: 'json',
              success: (resoult) => {
                if (resoult.StatusCode == 200) {
                  this.integral = resoult.Data.Score;
                }
              },
            });
          }
        },
      });
    },
    // 获取积分商品列表
    async getProductData() {
      let params = {
        PageModel: {
          page: 0,
          pageCount: 0,
          dataCount: 0,
          PageSize: 4,
          PageIndex: 1
        },
        DM: {
          Keyword: "" //ProductCode（商品编号）或 ProductName（商品名称)
        }
      };

      $.ajax({
        url: "https://score.haoyouzhushou.com/App/Product/GetProductList",
        // async: false,
        type: 'POST',
        data: JSON.stringify(params),
        contentType: 'application/json',
        dataType: 'json',
        success: (res) => {
          if (res.Data.Code === 0) {
            this.gameList = res.Data.Data;
          }
        },
      });
    },
    beautySub(str, len) {
      var reg = /[\u4e00-\u9fa5]/g, //专业匹配中文
        slice = str.substring(0, len),
        chineseCharNum = ~~(slice.match(reg) && slice.match(reg).length),
        realen = slice.length * 2 - chineseCharNum;
      return str.substr(0, realen) + (realen < str.length ? "..." : "");
    },
    // 一页展示多少条
    handleSizeChange(val) {
      this.pageIndex = 1;
      this.pageSize = val;
      this._GetGameList();
    },
    // 分页
    handleCurrentChange(val) {
      this.pageIndex = val;
      this._GetGameList();
      _czc.push(["_trackEvent", "免费玩页面", "分页点击", "统计量"]);
    },
    // 获取用户今天游戏时长
    async _GetGameTime() {
      let params = {
        phone: this.userInfo && this.userInfo.Phone
      }
      let res = await GetGameTime(params)
      if (res.StatusCode == 200) {
        if (res.Object > 60) {
          this._CompleteTask(1019)
        }
        if (res.Object > 300) {
          this._CompleteTask(1020)
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sign_in_wrap {
  height: 680px;
  overflow: auto;
  padding: 18px 20px;

  .point-box {
    .grid {
      height: 168px;
      display: flex;
      padding: 15px;
      @include item_bg_col();
      border-radius: 6px;
      box-sizing: border-box;

      .icon {
        margin-right: 15px;

        img {
          width: 70px;
          height: 70px;
          display: block;
        }
      }

      .content {
        height: 136px;
        display: flex;
        flex: 1;
        flex-direction: column;
        position: relative;

        .title {
          font-size: 20px;
          @include font_col3();

          i {
            color: #ff3636;
            margin: 0 5px;
          }

          span {
            margin-left: 20px;
            font-size: 16px;
            @include font_col6();
          }
        }

        .text {
          margin-top: 15px;
          @include font_col6();


          span {
            margin-right: 10px;
            font-size: 28px;
            @include font_col3();
          }
        }

        .text2 {
          margin-top: 15px;
          font-size: 13px;
          color: #ff3636;

          i {
            font-size: 15px;
          }
        }

        .btn {
          position: absolute;
          right: 0;
          bottom: 0;
        }

        .date-check {
          margin-top: 10px;
          display: flex;

          ul {
            display: flex;

            li {
              text-align: center;
              margin: 0 7px;

              .box {
                width: 78px;
                height: 78px;
                border-radius: 6px;
                border: 1px solid #FF5555;
                margin-bottom: 8px;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;

                .round {
                  width: 38px;
                  height: 38px;
                  border-radius: 19px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border: 1px dashed #FF5555;
                  color: #FF5555;
                }

                span {
                  width: 33px;
                  height: 17px;
                  background: #FF5555;
                  color: #fff;
                  border-radius: 6px 6px 6px 0px;
                  border: 1px solid #FFDFDF;
                  position: absolute;
                  top: 0;
                  left: 0;
                }
              }

              .el-button {
                padding: 2px 10px;
              }

              .el-button--primary.is-disabled {
                background: #FFDFDF;
                color: #ff5555;
              }
            }
          }
        }
      }
    }
  }

  .task-box {
    margin-top: 20px;

    .title-box {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      font-size: 22px;
      @include font_col3();

      img {
        margin-right: 5px;
      }

      span {
        margin-left: 20px;
        font-size: 15px;
        color: #999;
      }
    }

    .task {
      border-radius: 6px;
      padding: 20px;
      @include item_bg_col();

      .grid {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .left {
          display: flex;
          flex: 1;
          align-items: center;
          font-size: 16px;
          @include font_col3();

          .icon {
            margin-right: 10px;
          }

          span {
            margin-right: 20px;
            color: #ff3636;
          }
        }

        .is-disabled {
          color: #ff3636 !important;
          background: #FFDFDF !important;
        }
      }
    }
  }

  .points-mall {
    margin-top: 20px;

    .top-box {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title-box {
        display: flex;
        align-items: center;
        font-size: 22px;
        @include font_col3();

        img {
          margin-right: 5px;
        }

        span {
          margin-left: 20px;
          font-size: 15px;
          color: #999;
        }
      }

      .a-box {
        display: flex;
        justify-content: center;
        align-items: center;
        @include font_col9();

        a {
          font-size: 15px;
          @include font_col9();
          margin: 0 10px;
        }
      }
    }

    .main-box {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .main-item {
        // width: 240px;
        height: 196px;
        margin-right: 24px;
        margin-top: 13px;
        position: relative;
        box-sizing: border-box;
        border-radius: 6px;
        overflow: hidden;
        position: relative;
        @include item_bg_col();

        .icon-img {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 101;
        }

        a {
          width: 240px;
          height: 196px;
          display: block;
          position: relative;

          .icon {
            position: absolute;
            top: 0;
            right: 0;
            color: #fff;
            padding: 5px;
            font-size: 12px;
            background: red;
          }

          .game-img {
            width: 100%;
            height: 126px;
          }

          .item-wrapper {
            width: 100%;

            .item-box {
              display: flex;
              flex-direction: column;
              padding: 10px;

              h4 {
                font-size: 16px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                line-height: 20px;
                @include font_col3();

                .cloud-archiving {
                  padding: 5px;
                  margin-left: 10px;
                  background: $linear-col;
                  border-radius: 4px;
                  color: $white-col;
                  font-size: 12px;
                }
              }

              .btn-experience {
                display: flex;
                justify-content: space-between;
                line-height: 32px;

                .left {
                  font-weight: bold;
                  @include font_col3();
                  font-size: 16px;
                }

                .right {
                  text-decoration: line-through;
                  @include font_col9();
                }
              }
            }
          }
        }
      }

      .main-item:nth-child(4n) {
        margin-right: 0;
      }

      .main-item:hover {
        transform: translateY(-5px);
        transition: 0.2s ease;
        box-shadow: -2px 0px 10px 0px rgba(0, 0, 0, 0.2);

        h4 {
          color: $red-col;
        }

        .text-hidden {
          display: none;
        }
      }
    }
  }
}
</style>